<div class="flex h-full w-full flex-col">
  <div class="mb-4 flex items-center justify-between gap-6">
    <div class="flex shrink-0 items-center gap-6">
      <div class="flex items-center gap-2">
        <span class="text-sm uppercase text-gray-500">Project Team</span>
        <dominion-information-button
          [buttonHeight]="'h-4'"
          [buttonWidth]="'w-4'"
        >
          <div class="flex w-56 flex-col p-2">
            <p>
              This section contains members of your team. You can invite members
              of your team to join VOX to help you complete the onboarding
              process.
            </p>
          </div>
        </dominion-information-button>
      </div>

      <!-- ADD USER BUTTON -->
      @if (canInviteManager) {
        <button
          class="fill-dms-green [&_circle]stroke-dms-green text-dms-green flex shrink-0 cursor-pointer items-center gap-1 stroke-white text-sm"
          (click)="openInvite()"
        >
          <div class="relative h-5 w-5">
            <dominion-icon-plus-circle
              fillColor="fill-dms-green"
              strokeColor="stroke-white"
            ></dominion-icon-plus-circle>
          </div>
          <span>Add User</span>
        </button>
      }
    </div>

    <dominion-company-team
      [company]="company"
      [internalView]="internalView"
    ></dominion-company-team>
  </div>

  <dominion-table
    [hideFrame]="true"
    [isLoading]="isLoading"
    [isEmpty]="
      company
        ? company.users.length === 0 && company.invitedUsers.length === 0
        : true
    "
    class="-mx-4"
  >
    <thead>
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Role</th>
        <th scope="col">Title</th>
        <th scope="col"><span class="sr-only">View</span></th>
      </tr>
    </thead>
    <tbody>
      @if (company) {
        @for (user of company.invitedUsers; track user._id) {
          <tr [attr.data-testid]="'company-row-' + $index">
            <td>
              <div class="flex flex-col justify-center gap-0.5">
                <div class="flex items-center gap-2">
                  <span
                    title="Invited on {{ user.invite.invitedOn | date }}"
                    class="bg-dms-light-green/20 text-dms-green rounded-sm px-1 py-px text-xs font-medium"
                    >Invited</span
                  >
                  @if (user.isDeactivated) {
                    <div
                      data-testid="user-deactivated-badge"
                      class="rounded-sm border-red-600/20 bg-red-50 px-1 py-px text-xs font-medium text-red-600"
                    >
                      Deactivated
                    </div>
                  }
                </div>
                @if (user.email) {
                  <div class="text-xs font-medium text-gray-500">
                    {{ user.email }}
                  </div>
                }
              </div>
            </td>
            <td>
              @if (user.authorizations['company:' + company!._id].roles[0]) {
                <dominion-in-situ-select
                  #userRoleSelect
                  [allowNull]="false"
                  [config]="{ viewType: 'editable', searchable: false }"
                  [options]="
                    canInviteSuper | userRolePermissions: canInviteAdmin
                  "
                  [initialValue]="user | externalUserRole: company!._id"
                  [defaultValue]="'Role'"
                  (saved)="setUserAuthRole(user, $event, userRoleSelect)"
                  [isDisabled]="!canInviteAdmin"
                ></dominion-in-situ-select>
              } @else {
                <span class="text-gray-400">&mdash;</span>
              }
            </td>
            <td>
              <span class="text-gray-400">&mdash;</span>
            </td>
            <td align="right">
              <button
                (click)="goToUserDetail(user._id)"
                class="text-dms-green hover:bg-dms-light-green/20 group h-7 w-7 cursor-pointer truncate rounded-sm p-[6px] text-sm font-medium"
              >
                <dominion-icon-go-to
                  strokeColor="stroke-dms-green group-hover:stroke-dms-dark-green"
                ></dominion-icon-go-to>
              </button>
            </td>
          </tr>
        }
        @for (loopUser of company.users; track loopUser._id) {
          <tr [attr.data-testid]="'company-row-' + $index">
            <td>
              <div class="flex flex-col justify-center gap-0.5">
                <div class="flex items-center gap-2">
                  <span class="font-semibold"
                    >{{ loopUser.firstName }} {{ loopUser.lastName }}</span
                  >
                  @if (loopUser.isDeactivated) {
                    <div
                      data-testid="user-deactivated-badge"
                      class="rounded-sm border-red-600/20 bg-red-50 px-1 py-px text-xs font-medium text-red-600"
                    >
                      Deactivated
                    </div>
                  }
                </div>
                @if (loopUser.email) {
                  <div class="text-xs font-medium text-gray-500">
                    {{ loopUser.email }}
                  </div>
                }
              </div>
            </td>
            <td>
              @if (
                loopUser.authorizations['company:' + company!._id].roles[0]
              ) {
                <dominion-in-situ-select
                  #userRoleSelect
                  [allowNull]="false"
                  [config]="{ viewType: 'editable', searchable: false }"
                  [options]="
                    canInviteSuper | userRolePermissions: canInviteAdmin
                  "
                  [initialValue]="loopUser | externalUserRole: company!._id"
                  [defaultValue]="'Role'"
                  (saved)="setUserAuthRole(loopUser, $event, userRoleSelect)"
                  [isDisabled]="!canInviteAdmin || loopUser._id === user?._id"
                ></dominion-in-situ-select>
              } @else {
                <span class="text-gray-400">&mdash;</span>
              }
            </td>
            <td>
              @if (loopUser.jobTitle) {
                <span class="capitalize">{{ loopUser.jobTitle }}</span>
              } @else {
                <span class="text-gray-400">&mdash;</span>
              }
            </td>
            <td align="right">
              <button
                (click)="goToUserDetail(loopUser._id)"
                class="text-dms-green hover:bg-dms-light-green/20 group h-7 w-7 cursor-pointer truncate rounded-sm p-[6px] text-sm font-medium"
              >
                <dominion-icon-go-to
                  strokeColor="stroke-dms-green group-hover:stroke-dms-dark-green"
                ></dominion-icon-go-to>
              </button>
            </td>
          </tr>
        }
      }
    </tbody>
  </dominion-table>
</div>

<!-- INVITE MODAL -->
<dominion-modal title="Add User" #inviteModal>
  <form
    body
    class="flex min-h-[450px] min-w-[400px] flex-col"
    [formGroup]="inviteForm"
  >
    <!-- EMAIL TEXT INPUT -->
    <ng-container *ngIf="showInviteUser">
      <!-- Email Error -->
      <div
        *ngIf="
          inviteForm.get('email')?.invalid && inviteForm.get('email')?.touched
        "
        class="flex flex-row"
      >
        <div class="flex-1">
          <div dominionStandardFormError>
            Please enter the user's email address.
          </div>
        </div>
      </div>
      <!-- Email Label -->
      <div
        *ngIf="
          this.inviteForm.get('email')?.valid ||
          this.inviteForm.get('email')?.untouched
        "
        class="flex flex-row"
      >
        <div class="flex-1">
          <div dominionStandardFormLabel>User E-Mail</div>
        </div>
      </div>
      <!-- Email Input -->
      <div class="flex flex-row">
        <div class="flex-1">
          <input
            #emailInput
            class="outline-dms-light-green w-full overflow-hidden rounded-sm border p-2 text-center text-sm placeholder:font-light placeholder:italic"
            type="email"
            formControlName="email"
            placeholder="User E-mail Address"
          />
        </div>
      </div>
      <!-- Choose Existing User Button -->
      <div
        *ngIf="isPartOfGroup && userOptions.length > 0"
        class="flex flex-row"
      >
        <div
          class="hover:text-dms-ultra-grey mt-1 flex cursor-pointer text-xs font-light italic text-gray-500"
          (click)="toggleInviteUser()"
        >
          Choose an Existing User Instead
        </div>
      </div>
    </ng-container>

    <!-- DROPDOWN SEARCH COMPONENT -->
    <ng-container *ngIf="!showInviteUser && isPartOfGroup">
      <!-- Select User Error -->
      <div
        *ngIf="
          inviteForm.get('email')?.invalid && inviteForm.get('email')?.touched
        "
        class="flex flex-row"
      >
        <div class="flex-1">
          <div dominionStandardFormError>Please select a user.</div>
        </div>
      </div>
      <!-- Select User Label -->
      <div
        *ngIf="
          this.inviteForm.get('email')?.valid ||
          this.inviteForm.get('email')?.untouched
        "
        class="flex flex-row"
      >
        <div class="flex-1">
          <div dominionStandardFormLabel>Select User</div>
        </div>
      </div>
      <!-- Select User Dropdown -->
      <div class="flex flex-row">
        <div class="flex-1">
          <dominion-dropdown-search
            [options]="userOptions"
            [toggleText]="'Select a User'"
            [selectedOption]="selectedUser"
            [enableCreateNew]="true"
            [createNewLabel]="'Invite New User'"
            (emitClosed)="markUserDropdownAsTouched()"
            (emitCreateNew)="toggleInviteUser()"
            (emitSelectedValue)="selectUser($event)"
          ></dominion-dropdown-search>
        </div>
      </div>
    </ng-container>

    <!-- DROPDOWN SELECT ROLE -->
    <!-- Role Error -->
    <div
      *ngIf="inviteForm.get('role')?.invalid && inviteForm.get('role')?.touched"
      class="mt-6 flex flex-row"
    >
      <div class="flex-1">
        <div dominionStandardFormError>
          Please select a system role for the user.
        </div>
      </div>
    </div>
    <!-- Role Label -->
    <div
      *ngIf="inviteForm.get('role')?.valid || inviteForm.get('role')?.untouched"
      class="mt-6 flex flex-row"
    >
      <div class="flex-1">
        <div dominionStandardFormLabel>VOX Role</div>
      </div>
    </div>
    <!-- Role Dropdown -->
    <div class="flex flex-row">
      <div class="flex-1">
        <dominion-dropdown-search
          [searchable]="false"
          [options]="roleOptions"
          [toggleText]="'Select a Role'"
          [selectedOption]="selectedRole"
          [enableCreateNew]="false"
          (emitSelectedValue)="selectRole($event)"
          (emitClosed)="markRoleDropdownAsTouched()"
        >
        </dominion-dropdown-search>
      </div>
    </div>

    <!-- DROPDOWN SELECT COMPANY STRATEGY -->
    <ng-container *ngIf="isPartOfGroup">
      <!-- Company Strategy Error -->
      <div
        *ngIf="
          inviteForm.get('companyStrategy')?.valid ||
          inviteForm.get('companyStrategy')?.untouched
        "
        class="mt-6 flex flex-row"
      >
        <div class="flex-1">
          <div dominionStandardFormLabel>Add user to:</div>
        </div>
      </div>
      <!-- Company Strategy Label -->
      <div
        *ngIf="
          inviteForm.get('companyStrategy')?.touched &&
          inviteForm.get('companyStrategy')?.invalid
        "
        class="mt-6 flex flex-row"
      >
        <div class="flex-1">
          <div dominionStandardFormError>Please select a company strategy.</div>
        </div>
      </div>
      <!-- Company Strategy Dropdown -->
      <div class="flex flex-row">
        <div class="flex-1">
          <dominion-dropdown-search
            [searchable]="false"
            [options]="companyOptions"
            [toggleText]="'Select Applicable Companies'"
            [selectedOption]="selectedStrategy"
            [enableCreateNew]="false"
            (emitSelectedValue)="selectCompanyStrategy($event)"
            (emitClosed)="markCompanyStrategyDropdownAsTouched()"
          >
          </dominion-dropdown-search>
        </div>
      </div>
    </ng-container>

    <!-- SUBMIT BUTTON -->
    <div class="mt-8 flex flex-row">
      <div class="flex-1">
        <dominion-standard-form-submit
          type="submit"
          [disabled]="!inviteForm.valid"
          text="Add User"
          [isLoading]="isSendingInvite"
          (click)="addUser()"
        ></dominion-standard-form-submit>
      </div>
    </div>
  </form>
</dominion-modal>
