<!-- We reverse the layout to use the next sibling selector (~), taking
advantage of the builtin required attribute -->
<div
  [formGroup]="formGroup"
  class="flex flex-col-reverse gap-1.5"
  dominionPopoverHost
>
  @switch (errorStyle) {
    @case ('below') {
      @if (hasError) {
        <span class="text-sm text-red-700">{{ errorMessage }}</span>
      }
    }
    @default {
      <div dominionPopover>
        <span dominionStandardFormError>{{ errorMessage }}</span>
      </div>
    }
  }
  @if (type === 'textarea') {
    <textarea
      dominionPopoverTarget
      rows="3"
      [id]="id"
      [required]="required"
      [placeholder]="placeholder"
      [formControlName]="formControlName"
      class="border-dms-light-grey text-dms-dark-green peer relative flex w-full items-center overflow-hidden rounded border px-2 py-1 placeholder-gray-500 ring-offset-1 placeholder:text-sm focus-within:ring focus:outline-none focus:ring"
      [ngClass]="{ 'ring-error border-error ring': hasError }"
      (focus)="checkError()"
      (blur)="checkError()"
    ></textarea>
  } @else {
    <input
      dominionPopoverTarget
      [id]="id"
      [type]="type"
      [required]="required"
      [placeholder]="placeholder"
      [formControlName]="formControlName"
      class="border-dms-light-grey text-dms-dark-green peer relative flex h-10 w-full items-center overflow-hidden rounded border px-2 pb-px text-center placeholder-gray-800/40 ring-offset-1 placeholder:text-sm focus-within:ring focus:outline-none focus:ring"
      [ngClass]="{ 'ring-error border-error ring': hasError }"
      (focus)="checkError()"
      (blur)="checkError()"
    />
  }
  <label
    [for]="id"
    class="text-dms-dark-green block text-sm peer-required:after:text-red-700 peer-required:after:content-['*']"
    >{{ label }}</label
  >
</div>
