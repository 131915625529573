<div class="h-full w-full">
  <div class="mb-4 h-full w-full p-6">
    <div class="mb-4 flex flex-row space-x-10">
      <!-- COMPANY INFO -->
      <div class="flex basis-2/5 flex-col">
        <!-- COMPANY NAME -->
        <div class="flex flex-row text-3xl font-bold">
          <dominion-editable-text
            #name
            [text]="company?.name"
            [minLength]="1"
            [minInputSize]="8"
            [maxInputSize]="30"
            [placeholder]="'Company Name'"
            [errorMessage]="'Please enter a company name'"
            [disabled]="!canEditCompanyDetails"
            (saved)="onCompanyUpdate({ field: 'name', value: $event }, name)"
          ></dominion-editable-text>
        </div>

        <!-- COMPANY ADDRESS -->
        <div class="flex flex-col pl-8">
          <div class="flex flex-row text-lg">
            <dominion-editable-text
              #street
              [text]="company?.address?.street"
              [placeholder]="'Street Address'"
              [errorMessage]="'Please enter a street address'"
              [maxInputSize]="20"
              padding="p-0"
              (saved)="
                onCompanyUpdate({ field: 'street', value: $event }, street)
              "
              [disabled]="!canEditCompanyDetails"
            ></dominion-editable-text>
          </div>
          <div class="flex flex-row items-end text-lg">
            <dominion-editable-text
              #city
              [text]="company?.address?.city"
              [placeholder]="'City'"
              [errorMessage]="'Please enter a city'"
              [maxInputSize]="20"
              (saved)="onCompanyUpdate({ field: 'city', value: $event }, city)"
              padding="p-0"
              [disabled]="!canEditCompanyDetails"
            ></dominion-editable-text>
            <span>,</span>

            <dominion-editable-select
              #state
              [padding]="'px-[4px]'"
              [currentValue]="
                company
                  ? {
                      value: company.address.state,
                      label: company.address.state
                    }
                  : undefined
              "
              [options]="stateOptions"
              [required]="true"
              (selected)="
                onCompanyUpdate({ field: 'state', value: $event }, state)
              "
              padding="px-1"
              [disabled]="!canEditCompanyDetails"
            ></dominion-editable-select>

            <dominion-editable-text
              #zip
              [text]="company?.address?.zip"
              [placeholder]="'Zip'"
              [errorMessage]="'Please enter a 5-digit zip code'"
              [maxInputSize]="5"
              [minInputSize]="5"
              [minLength]="5"
              [maxLength]="5"
              (saved)="onCompanyUpdate({ field: 'zip', value: $event }, zip)"
              padding="p-0"
              [disabled]="!canEditCompanyDetails"
            ></dominion-editable-text>
          </div>
        </div>
      </div>

      <!-- PROJECT INFO -->
      <div class="flex basis-3/5 flex-row">
        <div class="flex flex-1 flex-col rounded bg-gray-100 p-2">
          <div class="mb-1 flex shrink flex-row justify-center pb-1">
            <div class="flex text-sm uppercase text-gray-500">
              Project Details
            </div>
          </div>
          <div class="flex flex-col">
            <div class="flex flex-row space-x-1">
              <div class="flex basis-1/4 flex-col p-2">
                <div
                  dominionInfoLabel
                  class="flex flex-row items-center justify-center space-x-1"
                >
                  <span>Go-Live Date</span>
                  <dominion-information-button
                    [strokeColor]="'stroke-gray-600 hover:stroke-white'"
                  >
                    <div class="w-64 p-2">
                      <p>
                        The <strong>Go-Live Date</strong> is the day that your
                        dealership begins using VUE for day-to-day business.
                      </p>
                    </div>
                  </dominion-information-button>
                </div>
                <div dominionValue class="flex flex-row justify-center">
                  <dominion-editable-text
                    #gld
                    [text]="
                      company
                        ? (company.goLiveDate | date: 'MM/dd/yyyy')
                        : undefined
                    "
                    type="date"
                    [placeholder]="'Go-Live'"
                    [minLength]="10"
                    [maxLength]="10"
                    [bgColor]="'bg-gray-100'"
                    [minInputSize]="10"
                    [maxInputSize]="10"
                    [errorMessage]="'Please enter a valid date (MM/DD/YYYY)'"
                    (saved)="
                      onCompanyUpdate(
                        { field: 'goLiveDate', value: $event },
                        gld
                      )
                    "
                    [disabled]="!canEditCompanyDetails"
                  ></dominion-editable-text>
                </div>
              </div>
              <div class="flex basis-1/4 flex-col p-2">
                <div dominionInfoLabel class="flex flex-row justify-center">
                  DMS
                </div>
                <div dominionValue class="flex flex-row justify-center">
                  <dominion-editable-select
                    #incumbent
                    *ngIf="company"
                    [currentValue]="{
                      value: company.dms,
                      label: getDMSLabel(company.dms)
                    }"
                    [required]="true"
                    [options]="IDMSOptions"
                    (selected)="
                      onCompanyUpdate(
                        { field: 'dms', value: $event },
                        incumbent
                      )
                    "
                    [disabled]="!canEditCompanyDetails"
                  ></dominion-editable-select>
                </div>
              </div>
              <div class="flex basis-1/4 flex-col p-2">
                <div
                  dominionInfoLabel
                  class="flex flex-row items-center justify-center space-x-1"
                >
                  <span>CED</span>
                  <dominion-information-button
                    [strokeColor]="'stroke-gray-600 hover:stroke-white'"
                  >
                    <div class="w-64 p-2">
                      <p>
                        The <strong>Contract Expiration Date</strong> (CED) is
                        the date that your contract with your current DMS
                        provider expires.
                      </p>
                    </div>
                  </dominion-information-button>
                </div>
                <div dominionValue class="flex flex-row justify-center">
                  <dominion-editable-text
                    #ced
                    [text]="
                      company
                        ? (company.contractExpirationDate | date: 'MM/dd/yyyy')
                        : undefined
                    "
                    [placeholder]="'CED'"
                    type="date"
                    [minLength]="10"
                    [maxLength]="10"
                    [bgColor]="'bg-gray-100'"
                    [minInputSize]="10"
                    [maxInputSize]="10"
                    [errorMessage]="'Please enter a valid date (MM/DD/YYYY)'"
                    (saved)="
                      onCompanyUpdate(
                        { field: 'contractExpirationDate', value: $event },
                        ced
                      )
                    "
                    [disabled]="!canEditCompanyDetails"
                  ></dominion-editable-text>
                </div>
              </div>
              <div class="flex basis-1/4 flex-col p-2">
                <div
                  dominionInfoLabel
                  class="flex flex-row items-center justify-center space-x-1"
                >
                  <span>PID</span>
                  <dominion-information-button
                    [strokeColor]="'stroke-gray-600 hover:stroke-white'"
                  >
                    <div class="w-64 p-2">
                      <p>
                        The <strong>Preferred Install Date</strong> is the date
                        that you would like to begin using VUE for day-to-day
                        business.
                      </p>
                    </div>
                  </dominion-information-button>
                </div>
                <div dominionValue class="flex flex-row justify-center">
                  <dominion-editable-text
                    #pid
                    [text]="
                      company
                        ? (company.preferredInstallDate | date: 'MM/dd/yyyy')
                        : undefined
                    "
                    [placeholder]="'PID'"
                    type="date"
                    [minLength]="10"
                    [bgColor]="'bg-gray-100'"
                    [maxLength]="10"
                    [minInputSize]="10"
                    [maxInputSize]="10"
                    [errorMessage]="'Please enter a valid date (MM/DD/YYYY)'"
                    (saved)="
                      onCompanyUpdate(
                        { field: 'preferredInstallDate', value: $event },
                        pid
                      )
                    "
                    [disabled]="!canEditCompanyDetails"
                  ></dominion-editable-text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- COMPANY PROJECT -->
    <div class="mt-8 grid grid-cols-3 gap-6">
      <dominion-company-project
        class="order-1 col-span-3 xl:col-span-2"
        [isLoading]="isLoading"
        [internalView]="internalView"
        [company]="company"
        [companyId]="company?._id"
        [isParentCompany]="
          (company &&
            company.childCompanies &&
            company.childCompanies.length > 0) ||
          false
        "
        [canAddModule]="canAddModule"
        [modules]="company?.modules"
        [canEditModuleDetails]="canEditModuleDetails"
        [canSeeInternalDetails]="canSeeInternalDetails"
        [canAssignModuleUser]="canAssignModuleUser"
        (emitAddModule)="addModuleToCompany($event)"
        (emitUpdateModuleDetails)="updateModuleDetails($event)"
        (emitUpdateSubmoduleDetails)="updateSubmoduleDetails($event)"
        (emitAssignUserToModule)="assignUserToModule($event)"
      ></dominion-company-project>

      <dominion-company-related-companies
        class="order-3 col-span-2 lg:col-span-1 xl:order-2"
        [isLoading]="isLoading"
        [company]="company"
        [internalView]="internalView"
      ></dominion-company-related-companies>

      <dominion-company-users
        class="order-2 col-span-full lg:col-span-2 xl:order-3"
        [user]="user"
        [company]="company"
        [isLoading]="isLoading"
        [canEditCompanyDetails]="canEditCompanyDetails"
        [canInviteSuper]="canInviteSuper"
        [canInviteAdmin]="canInviteAdmin"
        [canInviteManager]="canInviteManager"
        (emitAddUser)="inviteUser($event)"
        [internalView]="internalView"
      ></dominion-company-users>
    </div>

    <div class="h-20"></div>
  </div>
</div>

<!--

incumbent
cid
pid
contacts
projects
users
date created*
created by*
relationships
  parent
  children
  siblings

-->
